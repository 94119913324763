

//$main:rgb(1, 218, 218); //#f5ba1a; (255, 169, 0)
//$main-Dark:rgb(3, 161, 161); //#f5ba1a; (255, 169, 0)
//0085FF

$main:rgb(0, 133, 255,0.7);
$main-Dark:rgb(0, 133, 255);

$black:#000000;
$grey:#cccccc;

@font-face {
  font-family: 'syncopate-regular';
  src: url('./assets/syncopate.regular.ttf');
}

body {
	font: 400 14px/20px Roboto, "Poppins", sans-serif !important; 
	background: #f2f2f2;
}
.EverypayIframe{
  width: 100%;
  height: 650px;
}
.forgotPassword{
  text-align: right;
  margin-bottom: 21px;
  margin-top: -11px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.forgotPasswordText{
  cursor: pointer;
  margin-right: 10px;
}
.navBarLink{
  display: block;
  padding-left: 30px;
  padding-top: 30px;
  font-size: 16px;
  cursor: pointer;
  &.bonus_icon{
    color: orange;
    font-size: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 100px;
  }
  &.bonus_text{
    padding-right: 10px;

  }
}

.clearfix {
	&:after {
		content: "";
		display: block;
		clear: both;
		visibility: hidden;
		height: 0;
	}
}
.second_header{
  display: flex;
  height: 60px;
  &.registerLogin{
    justify-content: center;
  }
}
.back_button{
  height: 40px;
  width: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 5px;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid 2px rgba(0, 133, 255, 0.7);
  border-radius: 20px;
  background: $main;
  color: #fff;
  font-size: 16px;
  cursor:pointer;
}
.message{
  width: 430px;
  background-color: darkgreen;
  margin: 0 auto 0;
  color:white;
  display: flex;
  animation-name:messagePopup;
  animation-duration: 5.1s;

  @keyframes messagePopup{
    0% {padding-top: 0px; padding-bottom: 0px;}
    10% {padding-top: 18px; padding-bottom: 18px;}
    90% {padding-top: 18px; padding-bottom: 18px;}
    100% {padding-top: 0px; padding-bottom: 0px;}
  }
  .text{
    padding-left:10px
  }
}
.header {
  width: 430px;
	margin: 0 auto 0;
  display: flex;
  .navBarButton{
    min-width: 56px;
    background-color: #f2f2f2;
    box-shadow: none;
  }
  .header_data{
    width: 100%;

    .header_upper{
      display: flex;
      padding-top: 5px;
      padding-right: 10px;
      padding-left: 10px;
      font-size: 1.2em;
      color: $main-Dark;
    }
  
    .header_lower{
      display: flex;
      padding-top: 5px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      font-size: 1.2em;
      color: $main-Dark;
    }
  
    .balance,.value {
      text-align: right;
      width:100%;
      color: $main-Dark;
    }
    .first_name,.last_name{
      text-align: left;
      width:100%;
      color: $main-Dark;
    }
  }


}

.form_wrapper {
	background: #fff;
	width: 430px;
	max-width: 100%;
	box-sizing: border-box;
	padding: 20px;
  padding-top: 7px;
	margin: 0 auto 0;
	position: relative;
	z-index: 1;
	border-top: 3px solid $main;
	-webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    -webkit-transition: none;
    transition: none;
    h2 {
		font-size: 1.5em;
		line-height: 1.5em;
		margin: 0;
	}

  .buyCredit{
    display: flex;
    justify-content: center;
    &.comment{
      padding-top: 15px;
    }
    &.success{
      font-size: 20px;
      color: green;
    }
    &.failed{
      font-size: 20px;
      color: #8b0000;
    }
    &.confirmation{
      margin-top: 15px;
      font-size: 17px;
    }
    h1{
      padding-top: 40px;
      padding-bottom: 30px;
      font-size: 55px;
    }
    &.buttons{
      padding-top: 15px;
      .yes{
        color: #ffffff;
        background: $main;
        margin-left: 30px;
      }
      .no{
        color: $main;
        background: #ffffff;
      }
    }
    button{
      border: solid 2px rgba(0, 133, 255, 0.7);
      border-radius: 10px;
      font-size: 24px;
    }
  }

  .inputForm{
    display: flex;
    justify-content: center;

  }

  .input-amount{
    text-align: center;
    font-size: 42px;
    width: 100%;
  }

  mat-error{
    font-size:17px;
  }

  h4 {
		margin: 0;
	}

	.title_container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-right: 20px;
	}
    label {
        font-size: 12px;
    }
	.row {
		margin: 10px -15px;
		>div {
			padding: 0 15px;
			box-sizing: border-box;
		}
	}
	.input_field {
		position: relative;
		margin-bottom: 20px;
    .invalid-feedback {
      color:crimson;
    }
	}

	input {
    &[type="text"], 
    &[type="email"], 
    &[type="password"] {
      width: 100%;
      padding: 8px 10px 9px 35px;
      height: 35px;
      border: 1px solid $grey;
      border-radius: 20px;
      box-sizing: border-box;
      outline: none;
      -webkit-transition: all 0.30s ease-in-out;
      -moz-transition: all 0.30s ease-in-out;
      -ms-transition: all 0.30s ease-in-out;
      transition: all 0.30s ease-in-out;
    }
    &[type="text"]:hover, 
    &[type="email"]:hover, 
    &[type="password"]:hover {
      background: #fafafa;
    }
    &[type="text"]:focus, 
    &[type="email"]:focus, 
    &[type="password"]:focus {
      -webkit-box-shadow: 0 0 2px 1px rgba(1, 218, 218, 0.5);
      -moz-box-shadow: 0 0 2px 1px rgba(1, 218, 218, 0.5);
      box-shadow: 0 0 2px 1px rgba(1, 218, 218, 0.5);
      border: 1px solid $main;
      background: #fafafa;
    }
    &[type="submit"] {
      background: $main;
      height: 35px;
      line-height: 35px;
      width: 100%;
      border: none;
      outline: none;
      cursor: pointer;
      color: #fff;
      font-size: 1.1em;
      margin-bottom: 10px;
      -webkit-transition: all 0.30s ease-in-out;
      -moz-transition: all 0.30s ease-in-out;
      -ms-transition: all 0.30s ease-in-out;
      transition: all 0.30s ease-in-out;
      &:hover {
        background: darken($main,7%);
      }
      &:focus {
        background: darken($main,7%);
      }
	  }
  }
  .homeButton {
    background: $main;
    display:block;
    height: 35px;
    line-height: 35px;
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    font-size: 1.6em;
    text-align: center;
    margin-bottom: 15px;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    transition: all 0.30s ease-in-out;

    &.logIn{
      font-size: 20px;
      border-radius: 20px;
    }
    &.logOut{
      margin-top: 70px;
      border-radius: 20px;
      font-size: 20px;
    }

    &.pay{
      height: 60px;
      border-radius: 30px;
      margin-bottom: 30px;
      font-size: 30px;
      width: 100%;
    }
    &.next{
      margin-top: 50px;
      height: 60px;
      font-size: 30px;
      border-radius: 30px;
      &.inactive{
        background-color: #d9d9d9a8;
        color: #00000044;
      }
    }
    &:hover {
      background: darken($main,7%);
    }
    &:focus {
      background: darken($main,7%);
    }
  }
  .image_map{
    border-radius: 15px;
    border: solid 1px $main;
    &.laagna{
      margin-bottom: 64px;
    }
  }

  .cardBox{
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 18px;
    justify-items: center;
    &.platformList{
      grid-template-columns: repeat(1,1fr);
    }

    .card{
      position: relative;
      width: 60px;
      height: 60px;
      padding: 10px;
      border-radius: 10px;
      display:flex;
      justify-content: space-between;
      box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
      cursor:pointer;
      &.washBay{
        justify-content: center;
        align-items: center;
        font-size: 45px;
        border: solid 2px $main;
      }
      &.selected{
        color: #fff;
        background-color: $main;
      }
      &.platform{
        width: 300px;
        height: 45px;
        justify-content: center;
        align-content: space-around;
        flex-wrap: wrap;
        font-size: 22px;
        border: solid 2px $main;
        &.selected{
          color: #fff;
          background-color: $main;
        }
      }
      &.notSelected{
        color: grey;
        background-color: #d9d9d9a8;
      }
    }
    .cardText{
      position: absolute;
      width: 60px;
      text-align: center;
      top: 5px;
      font-size: 16px;
      color: $black;
    }
  }
  .price{
    text-align: center;
    font-size: 35px;
    margin: 10px;
    &.orderValue{
      font-size: 45px;
      margin-bottom: 40px;
    }

    &.orderConfirmSummary{
      margin-bottom: 15px;
      font-size: 18px;
    }

    &.confirmed{
      color: rgb(0, 173, 0);
      margin-bottom: 30px;
    }
    
  }
  .notEnoughBalance{
    text-align: center;
    font-size: 20px;
    color:#8b0000;
    padding-top: 10px;
  }
  .cardBoxOne{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;

    .cardOne{
      position: relative;
      width: 160px;
      height: 160px;
      padding: 10px;
      border-radius: 10px;
      display:flex;
      justify-content: space-between;
      
      cursor:pointer;
    }

    .offline{
      color: rgb(224, 224, 224);
    }

    .mat-progress-spinner circle, .mat-spinner circle {   
      stroke: $main;
    }
    
  }

  .buttonBox{
    
    text-align: center;
    .plusMinus{
      touch-action: manipulation;
      background-color: $main;
      color: #ffffff;
      border:none;
      width: 90px;
      height: 60px;
      border-radius: 10px;
      margin: 20px;
      font-size: 40px;
      cursor: pointer;
      
    }
  }

  mat-icon {
    font-size: 60px;
    color: $main-Dark;
    &.platformNotSelected{
      color: grey;
    }
    &.homePage_mat-icon{
      font-size: 45px;
      padding-left: 7px;
      padding-top: 15px;
    }
    &.bigOne{
      position: absolute;
      font-size: 160px;
    }
    &.network_off{
      position: absolute;
      color: rgb(255, 74, 74);
      font-size: 60px;
    }
    &.network_on{
      position: absolute;
      color: rgb(74, 255, 74);
      font-size: 60px;
    }
    &.edit{
      font-size: 20px;
      cursor:pointer;
      padding-left: 5px;
    }
    &.credit{
      font-size: 30px;
      margin-top: -7px;
      padding-left: 5px;
    }
    &.location{
      font-size: 30px;
    }
    &.payment{
      display: flex;
      justify-content: center;
      font-size: 150px;
      margin-top: -20px;
      margin-bottom: 120px;
    }
    &.success{
      color: green;
    }
    &.failed{
      color: #8b0000;
    }
  }
  
  .sectionTitleBox{
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 1px;
    justify-items: center;
    .sectionLine{
      position: relative;
      width: 100%;
      border-bottom: solid 2px #e6e6e6;
      display:flex;
      justify-content: space-between;
    }
  }
  .buyCreditRow{
    width: 100%;
    display: flex;
    justify-content: right;
    padding-bottom: 15px;
    .buyCreditText{
      border: solid 2px rgba(0, 133, 255, 0.7);
      border-radius: 20px;
      background: #ffffff;
      display: flex;
      padding: 10px;
      margin-bottom: 4px;
      color: $main;
      font-size: 16px;
      cursor: pointer;
      &.active{
        background: $main;
        color: #fff;
      }
    }
    &.left{
      justify-content: left;
      margin-bottom: -20px;
      margin-top: 10px;
    }
  }
  .logoContainer{
    color: #fff;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    margin-bottom: 20px;
    align-items: center;
    font-family: 'syncopate-regular';
    .logoName{
      background: #FFFFFF;
      color: $main-Dark;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: right;
      font-size: 50px;
      margin-left: 20px;
    }
    .logoDotcom{
      display: flex;
      background: $main;
      flex-wrap: wrap;
      align-content: flex-end;
      padding-left: 5px;
      width: 70px;
      margin-top: 15px;
      font-size: 20px;
    }
  }
  .slogan{
    color: $main-Dark;
    font-size: 20px;
    text-align: center;
    .row1{
      margin-left: -60px;
      margin-top: 50px;
      margin-bottom: 10px;
    }
    .row2{
      margin-bottom: 10px;
      margin-left: -80px;
    }
    .row3{
      margin-bottom: 10px;
      margin-left:20px
    }
    .row4{
      margin-left: 90px;
      margin-bottom: 50px;
    }
  }
  .listUser{
    position: relative;
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    &.header {
      height: 50px;
    }
    &.first{
      margin-top: 10px;
    }
    &.detailedInfo{
      display: block;
    }

    .row{
      display: flex;
      &.balanceEdit{
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }

    .listItem{
      padding-top: 8px;
      padding-bottom: 8px;
      align-self: center;
      &.profile{
        padding-left: 10px;
        width: 30%;
      }
      &.value{
       width: 70%;
       display: flex;
       justify-content: right;
       padding-right: 15px;
       &.profile{
        justify-content: left;
        &.email{
          font-size: 12px;
        }
       }
      }
      &.icon{
        justify-content: left;
        font-size: 20px;
        color: $black;
        cursor: pointer;
        &.blue{
          color: $main-Dark;
          padding-right: 5px;
        }
        &.yellow{
          color: rgb(159, 159, 0);
          padding-right: 5px;
        }
      }
      &.key{
        padding-left:33px;
        width: 30%;
      }
      &.detailed{
        padding-top: 0px;
        padding-bottom: 0px;
        justify-content: flex-start;
      }
      &.balance {
        width:0%;
        margin-left: 8px;
      }
      &.status {
        width: 0%;
      }
      &.statusEdit {
        width: 0%;
        margin-left: 26px;
      }

      &.transactionType{
        width: 35%;
        padding-left: 40px;
      }

      .transactionAmount{
        width:60px;
      }

    }
  }
  .balance_Info{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .userHeader_balance{
    font-size: 14px;
    margin-bottom: -20px;
    &.warning{
      margin-right: 50px;
    }
  }
  .userHeader_orders{
    color: #8b000088;
  }
  .userHeader_transactions{
    color: #00640088;
    margin-left: 7px 
  }

  .listBoxOrders{
    position: relative;
    width: 100%;
    margin-bottom: 5px;
    
    box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
    .orderUpperPart{
      display: flex;
      padding-top: 8px;
      .email{
        justify-content: left;
      }
      .amount{
        width: 100%;
        display: flex;
        justify-content: right;
      }
    }
    .orderLowerPart{
      display: flex;
      padding-bottom: 8px;
      .createdAt{
        width: 70%;
        justify-content: left;
      }
      .machine{
        width: 30%;
        display: flex;
        justify-content: right;
      }
    }
  }
}
.listBoxCards {
  display: flex;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);

  .logo{
    height: 30px;
    margin-top: 9px;
    padding-left: 4px;
  }
  .form-check-input{
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 40px;
  }

  .cardData{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
  }
  .newCard{
    display: flex;
    align-items: center;
  }
  .deleteCard{
    color: lightgray;
    font-size: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-right: 4px;
  }

}

@media (max-width: 600px) {
	.form_wrapper, 
  .header {
		width: 100%;
		float: none;
	}
  .message{
    width: 100%;
  }
	.form_container {
		border-left: none;
	}
  .logoName{
    margin-left: 50px !important;
  }
}
@media (max-width: 450px){
  .logoName{
    margin-left: 10px !important;
  }
}
@media (max-width: 390px){
  .logoName{
    margin-left: -10px !important;
  }
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background{
  background-color: $main;
}

.mat-ripple-element{
  display: none !important;
}


